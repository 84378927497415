const getLink = (userId: number, type: string, path: string) => {
    return `https://erp.og1.ru/${path}?modals=[{"type":"user_card","id":${userId},"userType":"${type}","layer":1}]`;
};

/**
 *
 * @param userInfo
 */
export const gteUserLink = (userInfo: any): string | undefined => {
    const isParent = userInfo?.roles?.includes('parent');
    if (isParent) {
        return getLink(userInfo.id, 'parent', 'parents');
    }
    return undefined;
};

/**
 *
 * @param user
 */
export const setChatWootUser = (user: any) => {
    const { id, email, roles, first_name, last_name, patronymic, avatar } = user;

    const link = gteUserLink(user);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.$chatwoot?.setUser?.(id, {
        name: `${last_name} ${first_name} ${patronymic}`,
        email: email || '',
        avatar_url: avatar || '',
        userRoles: roles || [],
        link: link || '',
    });
};
