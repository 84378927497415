import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { getAccessToken, getRefreshToken } from 'common/token';
import { getRedirectLink } from '../../helpers/redirectLink';

export const PrivateRoute: React.FC<{
    path: string;
    exact?: boolean;
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}> = ({ path, component, exact }) => {
    const redirectPath = window.location.pathname;
    if (!(getAccessToken() || getRefreshToken())) {
        const link = getRedirectLink('auth/login', { path: redirectPath.includes('new/auth') ? '/' : redirectPath });
        return <Redirect exact to={link} />;
    }

    return <Route exact={exact} path={path} component={component} />;
};
