import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { cn } from '@bem-react/classname';
import { ScheduleBoard, SchedulePageHeader } from '@lms-elements/schedule-board';
import { useGetSchedulePeriod } from 'hooks/useGetSchedulePeriod';

import {
    convertDateToHtmlDateString,
    getEndOfMonth,
    getEndOfWeek,
    getStartOfMonth,
    getStartOfWeek,
} from 'helpers/date';
import { getHomeworksObject } from 'helpers/schedule';

import './Schedule.scss';
import { FilterTypes, ScheduleEvent } from '../../redux/types/schedule';
import { getHomeworksBetweenDatesStart, loadLmsSchedule, loadLmsScheduleRange } from '../../redux/actions/shedule';
import { useAppSelector } from '../../redux/store';
import { FetchStatus } from '../../common/types';
import { useLoader } from '../../hooks/useLoader';
import { PageLoader } from '../../hoc/PageLoader';
import { PageHeader } from '../../components/PageHeader';
import { AssignmentProgressStatus } from '@lms-elements/atomic';
import { useOGChatWidget } from 'hooks/useOGChatWidget';

const CnSchedulePage = cn('schedulePage');

const isStudent = true;

export const SchedulePage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { scheduleHomeworksStatus, scheduleHomeworks, lmsSchedule, lmsScheduleStatus } = useAppSelector(
        (store) => store.schedule,
    );
    const { profile } = useAppSelector((store) => store.selectedProfile);
    const { user } = useAppSelector((store) => store.user);

    const [start, setStart] = useState<string | null>(null);
    const [end, setEnd] = useState<string | null>(null);

    const [lessonCheck, setLessonCheck] = useState(true);
    const [meetingCheck, setMeetingCheck] = useState(true);
    const [taskCheck, setTaskCheck] = useState(true);

    const { periodTime, periodLabel, currentDate } = useGetSchedulePeriod();

    useLoader([lmsScheduleStatus, scheduleHomeworksStatus]);

    useEffect(() => {
        if (periodTime === 'day' && start && profile?.lms_id) {
            const date = convertDateToHtmlDateString(new Date(start));
            dispatch(
                loadLmsSchedule({
                    external_id: profile?.lms_id,
                    query: { datetime_start: date, datetime_end: date },
                }),
            );
        } else if (start && end && profile?.lms_id) {
            const formattedStart = convertDateToHtmlDateString(start);
            const formattedEnd = convertDateToHtmlDateString(end);
            dispatch(
                loadLmsScheduleRange({
                    external_id: profile?.lms_id,
                    query: { datetime_start: formattedStart, datetime_end: formattedEnd, lesson_is_published: true },
                }),
            );
        }
    }, [periodTime, start, end, dispatch, profile?.lms_id]);

    useEffect(() => {
        if (start && end && profile?.lms_id) {
            dispatch(
                getHomeworksBetweenDatesStart([
                    {
                        externalId: profile?.lms_id,
                        filterType: FilterTypes.ASSIGNED,
                        dateStart: start,
                        dateEnd: periodTime === 'day' ? start : end,
                        statusFilters: isStudent
                            ? [AssignmentProgressStatus.ASSIGNED, AssignmentProgressStatus.DEFERRED_ASSIGNMENT]
                            : [],
                    },
                    {
                        externalId: profile?.lms_id,
                        filterType: isStudent ? FilterTypes.DEADLINE_FOR_COMPLETE : FilterTypes.DEADLINE_FOR_CHECK,
                        dateStart: start,
                        dateEnd: periodTime === 'day' ? start : end,
                        statusFilters: isStudent
                            ? [AssignmentProgressStatus.ASSIGNED, AssignmentProgressStatus.DEFERRED_ASSIGNMENT]
                            : [AssignmentProgressStatus.ON_CHECK],
                    },
                ]),
            );
        }
    }, [dispatch, end, isStudent, periodTime, start, profile?.lms_id]);

    useEffect(() => {
        if (periodTime === 'week') {
            const newStart = getStartOfWeek(currentDate);
            const newEnd = getEndOfWeek(currentDate);
            setStart(newStart);
            setEnd(newEnd);
        }

        if (periodTime === 'day') {
            setStart(currentDate);
            setEnd(currentDate);
        }

        if (periodTime === 'month') {
            const newStart = getStartOfMonth(currentDate);
            const newEnd = getEndOfMonth(currentDate);
            setStart(newStart);
            setEnd(newEnd);
        }
    }, [periodTime, currentDate]);

    const changeTablet = useCallback(
        (title) => {
            if (title === 'Неделя') {
                const newStart = getStartOfWeek(currentDate);
                const newEnd = getEndOfWeek(currentDate);
                history.push(`/schedule?period=week&date=${currentDate}`);
                setStart(newStart);
                setEnd(newEnd);
            }

            if (title === 'День') {
                history.push(`/schedule?period=day&date=${currentDate}`);
            }

            if (title === 'Месяц') {
                const newStart = getStartOfMonth(currentDate);
                const newEnd = getEndOfMonth(currentDate);
                history.push(`/schedule?period=month&date=${currentDate}`);
                setStart(newStart);
                setEnd(newEnd);
            }
        },
        [currentDate, history],
    );

    const handleChangeDate = useCallback(
        (newDate: string) => {
            history.push(`/schedule?period=${periodTime}&date=${newDate}`);
        },
        [history, periodTime],
    );

    const tasksMap = useMemo(() => {
        if (scheduleHomeworks) return getHomeworksObject(scheduleHomeworks, isStudent);
        else return null;
    }, [isStudent, scheduleHomeworks]);

    const handleChangeCheckbox = useCallback((checkboxName: string, isChecked: boolean) => {
        switch (checkboxName) {
            case 'lesson':
                setLessonCheck(isChecked);
                break;
            case 'meeting':
                setMeetingCheck(isChecked);
                break;
            case 'task':
                setTaskCheck(isChecked);
                break;
            default:
                break;
        }
    }, []);

    const dataMap = useMemo(() => {
        if (lmsSchedule) {
            const scheduleEntries = Object.entries(lmsSchedule);

            return scheduleEntries.reduce((result, entry) => {
                if (result[entry[0]]) {
                    result[entry[0]] = {
                        date: entry[1].date,
                        data: [...result[entry[0]].data, ...entry[1].data],
                    };
                } else {
                    result[entry[0]] = entry[1];
                }

                return result;
            }, {} as { [date: string]: { date: string; data: ScheduleEvent[] } });
        }
        return null;
    }, [lmsSchedule]);

    const checkboxes = useMemo(
        () => ({
            lessons: lessonCheck,
            meeting: meetingCheck,
            onCheckAssignment: taskCheck,
        }),
        [lessonCheck, meetingCheck, taskCheck],
    );

    const handleDayClick = useCallback(
        (date: string) => {
            history.push(`/schedule?period=day&date=${date}`);
        },
        [history],
    );
    const firstNameLetter = user?.first_name ? ' ' + user?.first_name[0] + '.' : '';
    const patronymicLetter = user?.patronymic ? ' ' + user?.patronymic[0] + '.' : '';
    const fullName = user?.last_name
        ? `${user?.last_name}${firstNameLetter}${patronymicLetter}`
        : `${user?.first_name ?? ''}`;
    return (
        <div className={CnSchedulePage('wrapper')}>
            <div className="cabinet-content col custom-scroll total-rating ">
                <div className={`layout ${CnSchedulePage()}`}>
                    <div className={CnSchedulePage('pageHeader')}>
                        {!!user && (
                            <>
                                <PageHeader
                                    user={{
                                        fullName: fullName,
                                        img: user?.avatar,
                                    }}
                                    title="Расписание"
                                />
                                <div>Время уроков указано по вашему часовому поясу</div>
                            </>
                        )}
                    </div>
                    <div className={CnSchedulePage('headerContainer')}>
                        <div className={CnSchedulePage('scheduleHeader')}>
                            <SchedulePageHeader
                                sliderTime={periodTime}
                                tablet={periodLabel}
                                date={currentDate}
                                checkboxes={checkboxes}
                                changeTablet={changeTablet}
                                changeDate={handleChangeDate}
                                changeCheckbox={handleChangeCheckbox}
                                withCheckboxes={!isStudent}
                            />
                        </div>
                    </div>
                    <div className={CnSchedulePage('scheduleContainer', { periodTime })}>
                        <PageLoader />
                        {scheduleHomeworksStatus === FetchStatus.FETCHED && lmsScheduleStatus === FetchStatus.FETCHED && (
                            <div className={CnSchedulePage('schedule', { periodTime })}>
                                <ScheduleBoard
                                    periodTime={periodTime}
                                    currentDate={currentDate}
                                    scheduleData={dataMap}
                                    taskData={tasksMap}
                                    onDayClick={handleDayClick}
                                    isLessonNeedHide={!lessonCheck}
                                    isMeetingNeedHide={!meetingCheck}
                                    isTaskNeedHide={!taskCheck}
                                    isStudent={true}
                                    isNeedHideLessonButton={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
