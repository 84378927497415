import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { loadUserSuccess, loadUserError, LOAD_USER } from '../actions/user';
import { FuncEpic } from '../../common/types';
import { setChatWootUser } from '../../helpers/setChatWootUser';

const loadUserEpic: FuncEpic = (action$: any, store$, deps) => {
    return action$.pipe(
        ofType(LOAD_USER),
        switchMap(() => {
            return from(deps.profileDataProvider.loadUser()).pipe(
                switchMap((user) => {
                    setChatWootUser(user);
                    return of(loadUserSuccess(user));
                }),
                catchError((err) => {
                    return of(loadUserError(err));
                }),
            );
        }),
    );
};

export const userEpics = combineEpics(loadUserEpic);
