import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
// import { TariffSelection } from './pages/TariffSelection';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import trackerAxios from '@openreplay/tracker-axios';
// import trackerProfiler from '@openreplay/tracker-profiler';
// import trackerRedux from '@openreplay/tracker-redux';
import axios from 'axios';
import { getAccessToken, removeAccessToken, removeRefreshToken } from 'common/token';
import { ConnectedRouter as Router } from 'connected-react-router';
import moment from 'moment';
import { ApplicationEnrollment } from 'pages/ApplicationEnrollment';
import { ChangePass } from 'pages/Auth/ChangePass';
import { ForgotPass } from 'pages/Auth/ForgotPass';
import { RegStudentStep1 } from 'pages/Auth/Reg/Student/Step/1';
import { RegStudentStep2 } from 'pages/Auth/Reg/Student/Step/2';

import { PrivateRoute } from 'components/PrivateRoute';

import 'moment/locale/ru';

import { PageStatus } from './common/types';
import { Alert } from './components/Alert/Alert';
import Header from './components/Header';
import BaseLayout from './hoc/BaseLayout/BaseLayout';
import { Login } from './pages/Auth/Login';
import { RegCompletion } from './pages/Auth/Reg/Completion';
import { RegParentStep1 } from './pages/Auth/Reg/Parent/Step/1';
import { RegParentStep2 } from './pages/Auth/Reg/Parent/Step/2';
import ChangeMeeting from './pages/ChangeMeeting';
import { Courses } from './pages/Courses';
import Meeting from './pages/Meeting';
import { Payment } from './pages/Payment';
import { Settings } from './pages/Settings';
import { loadProfile } from './redux/actions/profile';
import { loadUser } from './redux/actions/user';
import { history } from './redux/reducers';
import { State, useAppSelector } from './redux/store';
import { Contracts, Documents, Home, PaymentResultFail, PaymentResultSuccess, Schedule, TotalRating } from './pages';
import './App.scss';
import Cookies from 'universal-cookie';
import { logout } from './redux/actions/login';
import { loadMeta } from './redux/actions/meta';
import { useOGChatWidget } from 'hooks/useOGChatWidget';
import { getRedirectLink } from './helpers/redirectLink';

const tracker = new Tracker({
    projectKey: '4ELfwUKYv1hTQHKM2oIw',
    ingestPoint: 'https://or.og1.ru/ingest',
});

// export const profiler = tracker.use(trackerProfiler());
tracker.use(trackerAssist());
tracker.use(
    trackerAxios({
        instance: axios,
        failuresOnly: false,
    }),
);

// const fn = profiler('MyFunction')(() => {});

moment.locale('ru');

function App(props: any) {
    const { login } = useAppSelector((store) => store.login);
    const { profile } = useAppSelector((store) => store.selectedProfile);

    useEffect(() => {
        tracker.start();
    }, []);
    const dispatch = useDispatch();

    const _logout = useCallback(() => {
        const cookie = new Cookies();
        removeAccessToken();
        removeRefreshToken();
        cookie.remove('students');
        cookie.remove('user');
        dispatch(logout());
        const link = getRedirectLink('auth/login', { path: window.location.pathname });
        history.push(link);
    }, [dispatch, history]);
    useOGChatWidget(false);

    useEffect(() => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    _logout();
                }
                return Promise.reject(error);
            },
        );
    }, []);

    React.useEffect(() => {
        if (getAccessToken() || login.access) props.loadUser();
        if (getAccessToken()) {
            props.loadProfile();
            props.loadMeta();
        }
    }, [getAccessToken, login.access]);

    React.useEffect(() => {
        if (props.pageStatus === PageStatus.LOADED) {
            tracker.setUserID(props.user?.email || '');
        }
    }, [props.pageStatus, props.user?.email]);

    const link = getRedirectLink('', {}, true);

    return (
        <Router history={history}>
            <Switch>
                {/*<Route path="/auth" component={Login} exact />*/}
                {/*<Route path="/password_reset" component={ForgotPass} exact />*/}
                {/*<Route path="/password_reset/:uid/:token" component={ChangePass} exact />*/}
                {/*<Route path="/reg/parent/step/1" component={RegParentStep1} exact />*/}
                {/*<Route path="/reg/parent/step/2" component={RegParentStep2} exact />*/}
                {/*<Route path="/reg/student/step/1" component={RegStudentStep1} exact />*/}
                {/*<Route path="/reg/student/step/2" component={RegStudentStep2} exact />*/}
                {/*<Route path="/reg/completion" component={RegCompletion} exact />*/}
                {/*<PrivateRoute path="/application_enrollment" component={ApplicationEnrollment} exact />*/}
                <BaseLayout>
                    <Alert />
                    <Container style={{ paddingLeft: 0, paddingRight: 0 }} fluid>
                        <Row className="m-0 appRowContainer">
                            <Header />
                            <PrivateRoute path="/" component={Home} exact />
                            {/*<PrivateRoute path="/performance" component={Perfomance} exact />*/}
                            {/*<PrivateRoute path="/performance/marks/:class_id" component={Marks} exact />*/}
                            <PrivateRoute path="/documents" component={Documents} exact />
                            <PrivateRoute path="/settings/:user" component={Settings} exact />
                            <PrivateRoute path="/settings/trial-signup/:trialStudentId" component={Settings} exact />
                            <PrivateRoute path="/settings/tariff/:tariffStudentId" component={Settings} exact />
                            <PrivateRoute path="/schedule" component={Schedule} exact />
                            <PrivateRoute path="/payment" component={Payment} />
                            <PrivateRoute path="/pay" component={Payment} />
                            <PrivateRoute path="/contracts" component={Contracts} />
                            <PrivateRoute path="/sber/success" component={PaymentResultSuccess} exact />
                            <PrivateRoute path="/sber/fail" component={PaymentResultFail} exact />
                            <PrivateRoute path="/meeting" component={Meeting} exact />
                            {/* <PrivateRoute path="/meeting/:meetingId" component={Meeting} exact /> */}
                            {/*<PrivateRoute path="/meeting/change/" component={ChangeMeeting} exact />*/}
                            {!!profile?.moodle_id && <PrivateRoute path="/rating" component={TotalRating} exact />}
                            <PrivateRoute path="/courses" component={Courses} />
                            {/*<PrivateRoute path="/tariff-selection" component={TariffSelection} />*/}
                        </Row>
                    </Container>
                </BaseLayout>
                <Redirect to={link} from="/dashboard" />
            </Switch>
        </Router>
    );
}

export default connect(
    ({ user }: State) => ({ ...user }),
    (dispatch) => ({
        loadMeta: () => {
            return dispatch(loadMeta());
        },
        loadUser: () => {
            return dispatch(loadUser());
        },
        loadProfile: (pageStatus: PageStatus) => {
            return dispatch(loadProfile(pageStatus));
        },
    }),
)(App);
