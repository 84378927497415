/**
 *
 * @param query
 */
const getQueryString = (query?: Record<string, string>) => {
    const keys = Object.keys(query || {});
    return keys.length > 0
        ? `?${keys
              .filter((key) => query[key])
              .map((key) => `${key}=${query[key]}`)
              .join('&')}`
        : '';
};

/**
 *
 * @param page
 * @param query
 * @param isBase
 */
export const getRedirectLink = (page?: string, query?: Record<string, string>, isBase?: boolean) => {
    const search = new URLSearchParams(window.location.search);
    const searchParamsObject = Object.fromEntries(search.entries());
    const newQuery = {
        ...query,
        ...searchParamsObject,
    };

    const queryString = getQueryString(newQuery);

    return `/${isBase ? '' : 'new/'}${page ? `${page}/` : ''}${queryString}`;
};
